<template>
  <div class="wraper">
    <Type name="实训耗材分类" type="sx"></Type>
  </div>
</template>

<script>
export default {
  components:{
    Type: () => {
      return import("../components/Type.vue")
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}

</script>

<style>
</style>
